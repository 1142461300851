import Planet from "./Planet";

class Moon extends Planet {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
}

export default Moon;